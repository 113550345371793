<template>
  <div
    :style="{
      'background-color': '#ffffff',
      'background-size': 'cover',
    }"
  >
    <CContainer
      class="d-flex justify-content-center align-items-center min-vh-100"
    >
      <CRow class="col-md-12">
        <CCard
          color="white"
          text-color="dark"
          class="text-center col-md-6"
          body-wrapper
        >
          <CRow>
            <CCol md="12">
              <img src="/sss.png" class="img-fluid" />
              <hr />
              <br />
              <div>
                <img
                  src="https://www.flaticon.com/svg/static/icons/svg/148/148767.svg"
                  class="img-fluid"
                  style="width:20%"
                />
              </div>
              <br />
              <h4>สมัครรับบริการสำเร็จ</h4>
              <br />
              <CRow>
                <CCol col="12">
                  <CButton @click="success" block color="success" class="px-4">
                    เริ่มใช้งาน
                  </CButton>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCard>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import liff from '@line/liff'

export default {
  methods: {
    success() {
      liff.closeWindow()
    },
  },
}
</script>
